<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :wait="wait" btnCloseName="Закрыть">

    <template #title-left>{{ getTitle() }}</template>


    <v-virtual-scroll
        :items="items"
        :item-height="65"
        height="350px"
        style="opacity: 0.5;"
    >
      <template v-slot:default="{ item }">

        <!--        <v-list-item :class="[item.active  ? 'active' : 'not_active' ]">-->
        <v-list-item :class="{'active' : item.active, 'not_active':!item.active, 'deleted': item.deleted}">

          <v-list-item-avatar tile style="opacity: 0.5"
                              @click="resident_select = item; dialog_people=true">
            <v-img :src="get_img()"/>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title>
              <div style="display:inline-block">{{
                  item.person.nameDisplay ? item.person.nameDisplay : 'Нет данных'
                }}
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.doc ? item.doc.rightNumber : 'Нет данных договора' }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <!--                <v-btn depressed small>-->
            <!--                  <v-icon color="orange darken-4" right>mdi-open-in-new</v-icon>-->
            <!--                </v-btn>-->
            <div>{{ item.owner ? "Собственник" : "Жилец" }}</div>
            <div v-if="item.doc" style="font-size: x-small">{{  item.doc.rightRegDate|date }}</div>
          </v-list-item-action>
        </v-list-item>

        <v-divider/>

      </template>
    </v-virtual-scroll>


    <dialog-people v-model="dialog_people" :item="resident_select" :params="params"/>


  </v.dialog>
</template>


<script>

let normal_data = {}


export default {
  props: {value: Boolean, flat: Object, params: Object},
  data: function () {
    return {
      items: [
        {
          "active": true,
          "id": 1073,
          "owner": true,
          "person": {
            "id": 1069,
            "fullname": "",
            "company": false
          },
          "doc": {
            "id": 1071,
            "rightNumber": "50:20:0020410:2357-50/215/2020-7",
            "rightRegDate": "2020-09-23",
            "rightType": "SOWN",
            "create": "2022-06-21"
          }
        },
        {
          "active": true,
          "id": 1072,
          "owner": false,
          "person": {
            "id": 1068,
            "fullname": "",
            "company": false
          },
          "doc": {
            "id": 1070,
            "rightNumber": "50:20:0020410:2357-50/215/2020-5",
            "rightRegDate": "2020-09-23",
            "rightType": "SOWN",
            "create": "2022-06-21"
          }
        },
        {
          "active": true,
          "id": 1071,
          "owner": true,
          "person": {
            "id": 1067,
            "fullname": "",
            "company": false
          },
          "doc": {
            "id": 1069,
            "rightNumber": "50:20:0020410:2357-50/215/2020-6",
            "rightRegDate": "2020-09-23",
            "rightType": "SOWN",
            "create": "2022-06-21"
          }
        }
      ],
      wait: true,
      dialog_people: false,
      resident_select: null,
      param: {}
    }
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        if (!this.value) return // отрабатываем только закрытие формы
        this.$emit('input', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  watch: {
    // flat_edit: {
    //   deep: true, // глубокое наблюдение за объектом
    //   handler(newValue, oldValue) {
    //     newValue;
    //     oldValue
    //     if (this.flat_edit.floor === '') this.flat_edit.floor = null
    //     if (this.flat_edit.entrances === '') this.flat_edit.entrances = null
    //     this.btnOK_disabled = !this.$lib.different(this.flat, this.flat_edit)
    //   }
    // }
  },
  methods: {
    get_img() {
      return require('@/assets/avatar2.svg')
      // if (this.product.type === 'PRD') {
      //   return require('@/views/shop/assets/5.svg')
      // } else {
      //   return require('@/views/shop/assets/clock.svg')
      // }
    },
    getTitle() {
      let type = this.flat.apartment ? 'Помещение № ' : 'Квартира № '
      return type + ' ' + this.flat.number
    },
    form_show() {
      this.$lib.restore(this.$data, normal_data)
      // Заполням форму
      this.$axios.get(this.$url.server_project_flat_residents.replace('55555', this.flat.id), {zLoader: [this, 'wait']})
          .then(response => {
            this.items = response.data
          })
    },
    closeOK() {
      // используй this.$lib.compare(this.flat_edit,this.flat) для отправки только различий
      // this.$axios.patch(this.$url.server_project_flat.replace('55555', this.flat.id), this.flat_edit, {zLoader: [this, 'loading']})
      //     .then(response => {
      //       this.$lib.restore(this.flat, response.data)
      //       this.show = false
      //     })
    },
    // form_close() {
    //
    // },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
    "dialog-people": () => import('./dialog-people.vue'),
  }
}
</script>


<style scoped>

.deleted {
  opacity: 0.3 !important;
}

.active {
  opacity: 0.9 !important;
}

.not_active {
  opacity: 0.5;
}


.active:hover {
  opacity: 1 !important;
}

.not_active:hover {
  opacity: 1;
}



</style>